<template>
  <div class="container instructions">
    <b-row>
      <b-col lg="8">
        <article class="post-area single-blog">
          <div class="about-me">
            <div class="about-me-text mt-0 pt-0">
              <div class="mt-0 mb-5">
                <h5 class="mt-0">Table of contents</h5>

                <div>
                  <router-link
                      :to="{ name: 'EN_Instructions', hash: '#o-aplikaciji' }"
                  >About the app
                  </router-link
                  >
                </div>
                <div>
                  <router-link
                      :to="{ name: 'EN_Instructions', hash: '#kako-namestiti' }"
                  >How to install the app
                  </router-link>
                </div>
                <div>
                  <router-link
                      :to="{ name: 'EN_Instructions', hash: '#kako-dodati-zapis' }"
                  >How to post?
                  </router-link>
                </div>
                <div>
                  <router-link
                      :to="{ name: 'EN_Instructions', hash: '#zvrsti-in-podzvrsti' }"
                  >Categories and Subcategories
                  </router-link>
                </div>
                <div>
                  <router-link
                      :to="{ name: 'EN_Instructions', hash: '#filtriranje' }"
                  >
                    Filtering content
                  </router-link>
                </div>
                <div>
                  <router-link
                      :to="{
                      name: 'TermsAndConditions',
                      hash: '#pogoji-uporabe'
                    }"
                  >Terms of use and Privacy Policy
                  </router-link>
                </div>
              </div>

              <h2 id="o-aplikaciji" name="o-aplikaciji">ABOUT THE APP</h2>
              <p>
                Owner: Dobra pot Institute (<a
                  href="mailto:info@dobra-pot.si"
              >info@dobra-pot.si</a
              >)<br/>
                Type of app: progressive web application (PWA)<br/>
                Idea: Anu Kahuna<br/>
                Project lead: Nava Vardjan<br/>
                Development: SVON, Marko Zevnik s.p.<br/>
                Design: Pika-Barbara Ciuha<br/>
                Editorial Board: Sitka Tepeh, Nava Vardjan<br/>
              </p>

<!--              <iframe class="mt-4" width="560" height="315" src="https://www.youtube.com/embed/cuktfS4VcwY"-->
<!--                      title="YouTube video player" frameborder="0"-->
<!--                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--                      allowfullscreen style="width: 100%;"></iframe>-->

              <h2 id="kako-namestiti" name="kako-namestiti">
                How to install the app?
              </h2>
              <p>
                The app is a so-called "progressive web app" (PWA), which means that you can install it easily via a URL
                link by adding an icon to the screen of your mobile or desktop device. It works on iOs, Android,
                Windows, MAC, Linux.
              </p>
              <p><b>Installing to your mobile device::</b></p>
              <ul>
                <li>
                  Open the app's URL link
                  (http://zapisi-spomina.dobra-pot.si)
                </li>
                <li>
                  Some smart devices will automatically prompt you whether you want to install the app - in this case
                  just tap "Yes" and the icon will be placed on your home screen. If you are not automatically prompted,
                  open the browser settings on your mobile device.
                </li>
                <li>
                  In settings, scroll down to the "Add to Home Screen" option.
                </li>
                <li>Confirm by tapping "Add".</li>
                <li>
                  You will now have a 'Zapisi spomina' icon on your Home Screen.
                </li>
              </ul>

              <p><b>Installing to your computer desktop:</b></p>
              <ul>
                <li>
                  Open the app's URL link in your browser n (http://zapisi-spomina.dobra-pot.si)
                </li>
                <li>
                  Then click the "Install" button in the far right of the browser toolbar.
                </li>
                <li>
                  When the popup appears, click "Install" again.
                </li>
                <li>
                  You will now have a 'Zapisi spomina' icon on your computer desktop.
                </li>
              </ul>

              <h2 id="kako-dodati-zapis" name="kako-dodati-zapis">
                How to post?
              </h2>
              <p><b>Registration is required:</b></p>
              <ul>
                <li>
                  You must register first. You do this by clicking the button in the top right corner of the
                  application.
                </li>
                <li>
                  Once you have entered your details and read the Terms of Use and ticked the box to accept them, you
                  will receive a confirmation email with a link that you must click to activate your user account.
                </li>
                <li>
                  Then log in to the app with your username (email address) and password.
                </li>
              </ul>

              <p><b>Posting:</b></p>
              <ul>
                <li>
                  Once you are registered and logged in to the app, add a new post by clicking the plus symbol in the
                  bottom toolbar on mobile devices or the same symbol on the screen of your desktop device.


                </li>
              </ul>

              <p class="pl-4">
                Alternatively, click your username in the top right corner of a computer screen and then click on "New
                post" in the drop-down list. Or click the top green bar on a mobile device, then click your username and
                then click "New post".
              </p>

              <ul>
                <li>
                  The popup for adding content appears. Follow the instructions in each field. Please note that fields
                  marked with an asterisk (*) are mandatory. If you do not fill them in, you will not be able to save
                  and publish your content.
                </li>
                <li>
                  You can enter text, photos, videos, and audio clips. Each entry should have a title and a brief
                  summary of the content and should be submitted to the appropriate category and geographical region.
                </li>
                <li>
                  You may choose to include only the most relevant information or provide details about the author of
                  the story. The details will not be made public and will be kept in the
                  <router-link
                      :to="{ name: 'EN_Instructions', hash: '#vir-podatkov' }"
                  >archive
                  </router-link
                  >
                  .
                </li>
                <li>
                  When you finish entering your data, you have two options - you can either click "Save draft" or "Save
                  and Submit".
                </li>
                <li>
                  The first option means that your content will be saved as a draft, it will not be published yet, and
                  you will be able to edit, amend or delete it later.
                </li>
                <li>
                  Once you click "Save and Submit", your entry will be sent to editorial review and will soon be
                  published in the app. After that, you can no longer edit, amend, or delete the content - if you wish
                  to do so, you can contact the app manager.
                </li>
                <li>
                  If you encounter any problems when posting, please let us know at info@dobra-pot.si. We will do our
                  best to help you.
                </li>
              </ul>
              <p>We wish you a pleasant memory-posting experience!</p>

              <h2 id="zvrsti-in-podzvrsti" name="zvrsti-in-podzvrsti">
                Categories and Subcategories
              </h2>
              <p>
                According to the Convention for the Safeguarding of the Intangible Cultural Heritage, intangible
                heritage refers to practices, representations, expressions, knowledge, and skills, as well as
                instruments, objects, products and cultural spaces associated with them, which are recognized by
                communities, groups and individuals as part of their cultural heritage and are transmitted in a
                traditional manner from generation to generation.
              </p>
              <p>
                The content in the app is categorized based on ethnological categories and sub-categories as defined in
                the Rules on the Registry of Types of Heritage and Protection Guidelines (OJ of the Republic of
                Slovenia, Nr. 102/10), and according to the geographical areas of origin.
              </p>
              <p>
                The following guidelines may help you classify content in the appropriate category:
              </p>
              <p>
                1) <b>Oral tradition and Folk Literature</b> comprises the many human activities associated with
                linguistic expression as a traditional tool for the dissemination of folk traditions, information, and
                knowledge. This category includes dialects, proverbs and sayings, riddles, jokes and limericks, poetry,
                storytelling, and mythology.
              </p>
              <p>
                2) <b>Performances and Staging</b> comprises mostly of forms of musical, singing, dancing or theatrical
                presentation and artistic expression carried out by means of public performance. This category also
                includes folklore and tourism events based on elements of intangible heritage which have over time
                become part of local and national traditions. Games, divided into children's games, adult games, and
                sport games, are also part of the performance-like intangible heritage.
              </p>
              <p>
                3) <b>Customs and Traditions</b> are the diverse activities in terms social culture that mark and
                distinguish festive and everyday forms of human action, belief, and behaviour. This category includes
                annual customs, life-cycle customs, work and legal customs and celebrations in familiar and community
                contexts.
              </p>
              <p>
                4) <b>Knowledge on Nature and the Environment</b> are traditions relating to the plant and animal world,
                the weather, water, space, and the universe. They are reflected as intangible messages in human products
                or are directly expressed in oral tradition. This category also includes folk medicine as a simple,
                non-professional treatment of humans and domestic animals based on magical and natural practices.
              </p>
              <p>
                5) <b>Commercial Know-how and Skills</b> mean handicrafts and crafts, trade and transport, the knowledge
                and working practices of gathering, hunting, agriculture (e.g., farming, animal husbandry, viticulture,
                beekeeping), forestry, mining, and industry. They are a link between tangible and intangible heritage.
                This category also includes knowledge on food, which is an interplay of various manifestations of
                cultural elements in terms of food production, buying and selling, preparation and consumption of
                everyday, festive, and work dishes.
              </p>
              <p>
                6) <b>Other</b> is a category which consists of elements of intangible heritage that cannot be allocated
                to in any of the above categories.
              </p>

              <h2 id="filtriranje" name="filtriranje">Filtering content</h2>
              <p>
                The application allows you to filter the content according to
                1) the geographical area (Slovene statistical regions) from which the content originates,
                2) 6 main categories, and
                3) the authors of the posts.
                Filtering is activated clicking the region, the category, or the user.
              </p>
            </div>
          </div>
        </article>
      </b-col>
      <b-col lg="4">
        <Sidebar></Sidebar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";
import Sidebar from "@/components/EN_Sidebar";

export default {
  name: "Instructions",
  components: {
    Sidebar,
  },
  data() {
    return {
      users: [],
    };
  },
  metaInfo() {
    return {
      title: "Navodila"
    };
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  mounted() {
  },
  methods: {}
};
</script>
<style type="text/css" scoped>
* {
  color: #6d5a3f;
}

.instructions {
  text-align: justify;
}

a {
  color: #69a637 !important;
}

h2 {
  font-size: 18px;
  margin: 40px 0px 20px 0px;
}

h3 {
  font-size: 15px;
  margin: 20px 0px;
}

p {
  margin-bottom: 10px !important;
  font-size: 14px;
  line-height: 20px;
}

ul {
  list-style-type: circle;
}

li {
  font-size: 14px;
}
</style>
